import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import IconLink from "./IconLink";

const style = {
  footer: {
    backgroundColor: "#2a2a2a",
    padding: "15px",
    marginTop: "25px",
    bottom: "0px"
  },
  impCols: {
    paddingTop: "8px",
    paddingBottom: "12px",
    color: "#fff"
  }
};

class Footer extends Component {
  render() {
    return (
      <div style={style.footer}>
        <Container fluid={false}>
          <Row>
            <Col xs={12} sm={6} lg={4}>
              <Row>
                <Col xs={12}>
                  <IconLink
                    link="mailTo:office@johei.at"
                    icon={faEnvelope}
                    text="office@johei.at"
                    color="#fff"
                    border={false}
                  />
                </Col>
                <Col xs={12}>
                  <IconLink
                    link="tel:+4318946364"
                    icon={faPhone}
                    text="+43 1 8946364"
                    color="#fff"
                    border={false}
                  />
                </Col>
                <Col xs={12}>
                  <IconLink
                    link="https://www.google.com/maps/place/Johann+Heinisch/@48.1896108,16.3024821,15z/data=!4m5!3m4!1s0x0:0xdd66e242db14399d!8m2!3d48.1896108!4d16.3024821"
                    icon={faMapMarkerAlt}
                    text="Penzinger Str. 71/1, 1140 Wien"
                    color="#fff"
                    border={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6} lg={8}>
              <Row>
                <Col style={style.impCols} xs={12}>
                  Inhaber: <b>Johann Heinisch</b>
                </Col>
                <Col style={style.impCols} xs={12}>
                  Umsatzsteuer ID: <b>ATU 45819404</b>
                </Col>
                <Col style={style.impCols} xs={12}>
                  Fachgruppe: <b>Sanitär, Heizung, Lüftung</b>
                </Col>
                <Col style={style.impCols} xs={12}>
                  Kammerzugehörigkeit: <b>Kammer der gewerblichen Wirtschaft</b>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
