import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faAddressCard,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import IconLink from "./../components/IconLink";

const style = {
  container: {
    paddingTop: "15px"
  },
  headLineSmall: {
    margin: "15px 0px 5px 0px",
    fontWeight: "300",
    fontSize: "1.25rem",
    color: "#616161"
  },
  primary: {
    color: "#e83021"
  },
  secondary: {
    color: "#005b95"
  },
  data: { margin: "0px -15px 15px -15px" },
  iconCols: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: "15px"
  },
  impCols: {
    paddingTop: "24px",
    paddingBottom: "9px"
  },
  headLineText: {
    marginBottom: "0px"
  },
  text: {
    marginTop: "5px"
  }
};

class Impressum extends Component {
  render() {
    return (
      <Container style={style.container}>
        <Row>
          <Col xs={12}>
            <h5 style={style.headLineSmall}>Impressum</h5>
            <p>
              Offenlegung und Information gem. § 25 MedienG und gem. § 5 ECG
            </p>
          </Col>
        </Row>
        <Row style={style.data}>
          <Col xs={12} lg={4}>
            <Row>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  icon={faAddressCard}
                  text="Johann Heinisch, Installateur"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  link="mailTo:office@johei.at"
                  icon={faEnvelope}
                  text="office@johei.at"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  link="tel:+4318946364"
                  icon={faPhone}
                  text="+43 1 8946364"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <div>
                  <IconLink
                    link="https://www.google.com/maps/place/Johann+Heinisch/@48.1896108,16.3024821,15z/data=!4m5!3m4!1s0x0:0xdd66e242db14399d!8m2!3d48.1896108!4d16.3024821"
                    icon={faMapMarkerAlt}
                    text="Penzinger Str. 71/1, 1140 Wien"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={8}>
            <Row>
              <Col style={style.impCols} xs={12}>
                Inhaber: <b>Johann Heinisch</b>
              </Col>
              <Col style={style.impCols} xs={12}>
                Umsatzsteuer ID: <b>ATU 45819404</b>
              </Col>
              <Col style={style.impCols} xs={12}>
                Fachgruppe: <b>Sanitär, Heizung, Lüftung</b>
              </Col>
              <Col style={style.impCols} xs={12}>
                Kammerzugehörigkeit: <b>Kammer der gewerblichen Wirtschaft</b>
              </Col>
              <Col style={style.impCols} xs={12}>
                Gerichtsort: <b>Wien</b>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h3 style={style.headLineText}>Grundlegende Richtung</h3>
            <p style={style.text}>
              Eigenwerbung und aktuelle Neuigkeiten des Diensteanbieters
            </p>
          </Col>
          <Col xs={12}>
            <h3 style={style.headLineText}>Haftung</h3>
            <p style={style.text}>
              Sämtliche Texte auf dieser Website wurden sorgfältig geprüft.
              Dessen ungeachtet kann keine Garantie für Richtigkeit,
              Vollständigkeit und Aktualität übernommen werden.
            </p>
          </Col>
          <Col xs={12}>
            <h3 style={style.headLineText}>Links</h3>
            <p style={style.text}>
              Links auf diese Website sind erwünscht, jedoch ist eine
              Darstellung dieser Website in einem eigenen Frame des Linksetzers
              unzulässig. Eigene Links auf für uns fremde Seiten stellen nur
              Wegweiser zu diesen Seiten dar; diese werden deshalb grundsätzlich
              in einem eigenen Fenster des Browsers aufgerufen. Der
              Diensteanbieter identifiziert sich nicht mit den Inhalten der
              verlinkten Seiten und übernimmt dafür keine Haftung. Sollte eine
              dieser verlinkten Seiten bedenkliche oder rechtswidrige Inhalte
              aufweisen, wird um Mitteilung ersucht - in einem solchen Fall wird
              der Link unverzüglich gelöscht.
            </p>
          </Col>
          <Col xs={12}>
            <h3 style={style.headLineText}>Urheberrecht ("Copyright"):</h3>
            <p style={style.text}>
              Sämtliche Inhalte dieser Website sind urheberrechtlich geschützt.
              Dies umfasst Design, Texte, Grafiken und Bilder. Die Verwendung
              durch Dritte ist nur mit ausdrücklicher Genehmigung des
              Urheberrecht-Inhabers zuläßig. Es gilt österreichisches Recht als
              vereinbart. Gerichtsort ist Wien/Österreich.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Impressum;
